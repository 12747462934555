import React, { useEffect, Suspense, lazy /*, { useEffect }*/ } from 'react';
import { Route } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { Admin, Resource } from 'react-admin';
import { withAuthenticator, RequireNewPassword, VerifyContact, TOTPSetup } from 'aws-amplify-react';
import Auth from '@aws-amplify/auth';
import { datadogLogs } from '@datadog/browser-logs';
// import Analytics from '@aws-amplify/analytics';

import { createLabel, createReturnLabel } from 'pages/calculator';
import { calculator } from 'pages/newCalculator';
import groupedOrders from 'pages/orders/components/group/GroupedOrders';
import groupCalculator from 'pages/orders/components/group/GroupedCalculator';
import groupedShipments from 'pages/shipments/components/group/GroupedShipments';
import { help } from 'pages/help';
import privacyPolicy from 'pages/privacyPolicy';
import inventorySettings from 'pages/inventorySettings';
import totpSettings from 'pages/totpSettings';
import tiktokConnect from 'pages/sellingChannels/components/TikTokConnect';

import printAndEmail from 'pages/printAndEmail';
import notificationPreferences from 'pages/notificationPreferences';
import profile from 'pages/profile';
import {
  orders,
  sellingChannels,
  shipmentAccounts,
  shipments,
  schedulePickup,
  pickupHistory,
  shippingPresets,
  warehousePresets,
  billing,
  endOfDay,
  addressBook,
  inventory,
} from './pages';
import {
  ConfirmationWasSent,
  OAuthRedirect,
  RecoveryWasSent,
  SignIn,
  SignUp,
  ConfirmSignIn,
} from './pages/auth';
import CustomResource from 'components/common/CustomResource';
import Layout from './components/layout/Layout';
import CalculatorButton from 'components/buttons/CalculatorButton';

import data from './services/data';
import reducers from 'store/reducer';
import { isMobile } from 'services/helpers/isMobile';
import { config } from './services/auth';

import ConfirmSignUp from './pages/auth/ConfirmSignUp';

const GroupSettings = lazy(() => import('pages/groupingAliases/GroupSettings'));
const HomePage = lazy(() => import('pages/home/HomePage'));
const MobileView = lazy(() => import('pages/mobile/MobileView'));

Auth.configure(config);

// Analytics.configure(analyticsConfig)

// Analytics.autoTrack('session', {
//   enable: true
// });

// Analytics.autoTrack('pageView', {
//   enable: true,
//   type: 'SPA'
// });

// Analytics.autoTrack('event', {
//   enable: true
// });

const history = createHistory();

const App = () => {
  // useEffect(() => {
  //   Analytics.record({
  //     name: "initApp"
  //   })
  // }, [])

  useEffect(() => {
    datadogLogs.init({
      clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
      site: process.env.REACT_APP_DATADOG_SITE,
      env: process.env.REACT_APP_AWS_ENV,
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <Admin
        dataProvider={data}
        layout={Layout}
        history={history}
        customReducers={reducers}
        customRoutes={[
          <Route key="profile" path="/profile" component={profile.edit} />,
          <Route key="privacy-policy" path="/privacy-policy" component={privacyPolicy.show} />,
          <Route key="print-and-email" path="/print-and-email" component={printAndEmail.show} />,
          <Route
            key="notificationPreferences"
            path="/notificationPreferences"
            component={notificationPreferences.show}
          />,
          <Route
            key="inventorySettings"
            path="/inventory-settings"
            component={inventorySettings.show}
          />,
          <Route key="totpSettings" path="/totp-settings" component={totpSettings.show} />,

          <Route key="ship-order" path="/orders/:id/calculate" component={createLabel} />,
          <Route key="confirm-signup" path="/confirm-signup" component={ConfirmSignUp} />,
          <Route key="return-label" path="/shipments/:id/return" component={createReturnLabel} />,
          <Route key="help" path="/help/:id*" component={help} />,
          <Route key="endOfDay" path="/shipments/end-of-day" component={endOfDay.list} />,
          <Route key="pickupHistory" path="/schedule/history" component={pickupHistory.list} />,
          <Route key="groupedOrders" path="/orders/groups/:id/show" component={groupedOrders} />,
          <Route
            key="groupPrices"
            path="/orders/groups/:id/calculate"
            component={groupCalculator}
          />,
          /*<Route
          key="addressBook"
          path="/address-book"
          component={addressBook.list}
        />,*/
          <Route
            key="groupedShipments"
            path="/shipments/groups/:id/show"
            component={groupedShipments}
          />,
          <Route key="tiktokConnect" path="/connect/tiktok" component={tiktokConnect} />,
        ]}>
        <Resource
          name="home"
          options={{ label: 'Home', menu: 'primary', order: 1 }}
          list={HomePage}
        />
        <Resource name="orders" options={{ label: 'Orders', menu: 'primary' }} {...orders} />
        <Resource name="shipments" options={{ label: 'Shipped', menu: 'primary' }} {...shipments} />
        <Resource
          name="inventory"
          options={{ label: 'Inventory', menu: 'primary' }}
          {...inventory}
        />
        <Resource
          name="schedule"
          options={{ label: 'Schedule Pickup', menu: 'primary' }}
          {...schedulePickup}
        />
        <Resource name="contacts" options={{ label: 'Contacts', menu: 'user' }} {...addressBook} />
        <CustomResource
          name="calculator"
          path="/calculator"
          options={{ label: <CalculatorButton />, menu: 'actions' }}
          component={calculator}
        />
        <Resource
          name="sellingChannels"
          options={{ label: 'Selling Channels', menu: 'connections' }}
          {...sellingChannels}
        />
        <Resource
          name="shipmentAccounts"
          options={{ label: 'Shipping Carriers', menu: 'connections' }}
          {...shipmentAccounts}
        />
        <Resource
          name="shippingPresets"
          options={{ label: 'Package Presets', menu: 'settings' }}
          {...shippingPresets}
        />
        <Resource
          name="warehousePresets"
          options={{ label: 'Ship From Addresses', menu: 'settings' }}
          {...warehousePresets}
        />
        <Resource
          name="grouping"
          options={{ label: 'Group', menu: 'settings' }}
          list={GroupSettings}
        />
        <Resource name="billing" {...billing} />
        <Resource name="shippingPreset" />
        <Resource name="singlePickupList" />
        <Resource name="automaticPickupList" />
        <Resource name="packageTypes" />
        <Resource name="rates" />
        <Resource name="countries" />
        <Resource name="states" />
        <Resource name="labelInfo" />
        <Resource name="stores" />
        <Resource name="orderStatuses" />
        <Resource name="shipmentStatuses" />
        <Resource name="shipmentTypes" />
        <Resource name="singlePickupStatuses" />
        <Resource name="orderItems" />
        <Resource name="deliveryProviders" />
        <Resource name="ebayChannel" />
        <Resource name="profile" />
        <Resource name="billingBalance" />
        <Resource name="billingStats" />
        <Resource name="paymentOptions" />
        <Resource name="additionalDocument" />
        <Resource name="tableSettings" />
        <Resource name="rowsPerPageSettings" />
        <Resource name="returnShipments" />
        <Resource name="paymentStatuses" />
        <Resource name="scanForms" />
        <Resource name="promoteShipmentAccount" />
        <Resource name="pickupHistory" />
        <Resource name="scanFormsHistory" />
        <Resource name="scanFormsShipments" />
        <Resource name="scanFormsWarehouses" />
        <Resource name="scanFormsProviders" />
        <Resource name="printScanForms" />
        <Resource name="groups" />
        <Resource name="groupedOrdersIds" />
        <Resource name="groupPrices" />
        <Resource name="groupSettings" />
        <Resource name="groupLabels" />
        <Resource name="groupShipments" />
        <Resource name="groupShipmentsLabels" />
        <Resource name="groupOrdersInfo" />
        <Resource name="groupShipmentsInfo" />
        <Resource name="deliveryServices" />
        <Resource name="groupedShipmentsIds" />
        <Resource name="userServices" />
        <Resource name="shipmentMethodGroups" />
        <Resource name="skuGroups" />
        <Route path="/redirect" component={OAuthRedirect} />
      </Admin>
    </Suspense>
  );
};

const AuthWrapper = ({
  component,
  ...props
}: { component?: (props: any) => JSX.Element | null } & any) => {
  const Component = component;
  return <Component {...props} />;
};

const MobileViewWrapper = () => {
  return (
    <Suspense fallback={null}>
      <MobileView />
    </Suspense>
  );
};

export default isMobile()
  ? MobileViewWrapper
  : withAuthenticator(App, false, [
      <AuthWrapper
        component={(props) => (
          <Suspense fallback={null}>
            <SignIn {...props} />
          </Suspense>
        )}
      />,
      <AuthWrapper
        component={(props) => (
          <Suspense fallback={null}>
            <RecoveryWasSent {...props} />
          </Suspense>
        )}
      />,
      <AuthWrapper
        component={(props) => (
          <Suspense fallback={null}>
            <SignUp {...props} />
          </Suspense>
        )}
      />,
      <AuthWrapper
        component={(props) => (
          <Suspense fallback={null}>
            <ConfirmationWasSent {...props} />
          </Suspense>
        )}
      />,
      // below components should be implemented in case auth flow changed
      <AuthWrapper
        component={(props) => (
          <Suspense fallback={null}>
            <ConfirmSignIn {...props} />
          </Suspense>
        )}
      />,
      <AuthWrapper
        component={(props) => (
          <Suspense fallback={null}>
            <RequireNewPassword {...props} />
          </Suspense>
        )}
      />,
      <AuthWrapper
        component={(props) => (
          <Suspense fallback={null}>
            <VerifyContact {...props} />
          </Suspense>
        )}
      />,
      <TOTPSetup />,
    ]);
